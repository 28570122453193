import axios from "axios";

export const host = "https://owl-api.esports778.com" // 主机
// export const host = "http://106.75.156.60:8000";
// export const host = "http://106.75.156.60:33002"

const R_code = "/user/v1/register/sms-send"; // 注册短信code
const L_code = '/user/v1/login/sms-send';
const F_code = '/user/v1/forget/sms-send';
const R = '/user/v1/register/do-register';
const L = '/user/v1/login/do-login';
const F = '/user/v1/forget/check';
const R_P = '/user/v1/forget/reset';
const U_C = '/user/v1/get-user-info';
const M_U = '/user/v1/modify-user-info';
const wx_place = '/user/v1/member/wx/places';
const ali_place = '/user/v1/member/alipay/places';
const wx_renew = '/user/v1/member/wx/place';
const al_renew = '/user/v1/member/alipay/place';
const c_o = '/user/v1/member/query-order-status';
const o_l = '/user/v1/get-order-list';
const k_i = '/user/v1/get-key-info';
const r_t = '/user/v1/reset-gateway-auth-key';
const email_login = '/user/v1/login/email-do-login';
const email_register = '/user/v1/register/email-register';
const forget_email_send = '/user/v1/forget/email-send';
const forget_email_reset = '/user/v1/forget/email-reset';

export function exitLogin() {
  delete axios.defaults.headers.common.token;
  localStorage.removeItem('token');
}

export function setToken(token) {
  let t = token;
  if (!token) {
    t = localStorage.getItem('token');
  } else {
    localStorage.setItem('token', token);
  }
  reqToken(t);
}

export function reqToken(token) {
  axios.defaults.headers.common.token = token;
}

function request(url, q = {}) {
  const headers = q.headers;
  const method = q.method || 'get';
  const data = q.data || {};
  const params = q.params || {};
  return axios({
    url,
    data,
    params,
    method,
    headers: headers || {},
  }).then(res => res.data).catch(err => Promise.reject(err.response));
}

export const getRegCode = (mobile) => request(`${host}${R_code}`, { data: { mobile }, method: 'post' });
export const getLoginCode = (mobile) => request(`${host}${L_code}`, { data: { mobile }, method: 'post' });
export const getForgetCode = (mobile) => request(`${host}${F_code}`, { data: { mobile }, method: 'post' });

export const register = (data) => request(`${host}${R}`, { data, method: 'post' });
export const login = (data) => request(`${host}${L}`, { data, method: 'post' });
export const forget = (data) => request(`${host}${F}`, { data, method: 'post' });
export const resetPassword = (data) => request(`${host}${R_P}`, { data, method: 'post' });
export const getUserInfo = () => request(`${host}${U_C}`);
export const modifyUser = (data) => request(`${host}${M_U}`, { data, method: 'post' });
export const wxBuy = (product_ids) => request(`${host}${wx_place}`, { data: { product_ids }, method: 'post' });
export const aliBuy = (product_ids) => request(`${host}${ali_place}`, { data: { product_ids }, method: 'post' });
export const checkOrder = (id) => request(`${host}${c_o}`, { data: { id }, method: 'post' });
export const getOrderList = (params) => request(`${host}${o_l}`, { params });
export const getKeyInfo = () => request(`${host}${k_i}`);
export const resetToken = () => request(`${host}${r_t}`);
export const renewWx = (product_id) => request(`${host}${wx_renew}`, { data: { product_id }, method: 'post' });
export const renewAli = (product_id) => request(`${host}${al_renew}`, { data: { product_id }, method: 'post' });
export const emailLogin = (data) => request(`${host}${email_login}`, { data, method: 'post' });
export const emailRegister = (data) => request(`${host}${email_register}`, { data, method: 'post' });
export const emailForget = (data) => request(`${host}${forget_email_send}`, { data, method: 'post' });
export const emailReset = (data) => request(`${host}${forget_email_reset}`, { data, method: 'post' });
// export const login_s = (data) => request(`${host}${}`)